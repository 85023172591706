import React from 'react'
import { Alert, Button, Divider, Image, Layout, Space, Typography } from 'antd'
import SignInButton from './SignInButton'

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const Login = () => {
    return (
        <>
            <Layout style={{ height: '100vh', width: '100vw' }}>
                <Header style={{ position: 'sticky', zIndex: 1, width: '100%' }}>
                    <Title level={1} style={{ color: '#fff', margin: '7px 7px' }}>{process.env.REACT_APP_TITLE}</Title>
                </Header>

                <Content>
                    <div style={{ position: 'fixed', top: '45%', textAlign: 'center', width: '100%' }}>
                        {/* <Text>Please click on Sign in button to enter the chat room.</Text> */}
                        <div style={{ margin: 'auto', margin: 20}}>
                                        <SignInButton />
                                    </div>
                                     <a style={{marginTop: 20, color: '#1890ff'}} href='mailto:DL-R-GLOBAL-genaichat-support@dupont.com?subject=GenAI IP21 Knowledge Finder Access Request' target='_blank' >Don't have access? Click here to send an email request.</a>

                    </div>
                </Content>
            </Layout>
        </>
    )
}

export default Login